exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-admin-blogs-table-js": () => import("./../../../src/pages/admin/admin-blogs-table.js" /* webpackChunkName: "component---src-pages-admin-admin-blogs-table-js" */),
  "component---src-pages-admin-admin-home-js": () => import("./../../../src/pages/admin/admin-home.js" /* webpackChunkName: "component---src-pages-admin-admin-home-js" */),
  "component---src-pages-admin-admin-image-upload-js": () => import("./../../../src/pages/admin/admin-image-upload.js" /* webpackChunkName: "component---src-pages-admin-admin-image-upload-js" */),
  "component---src-pages-admin-admin-jobs-table-js": () => import("./../../../src/pages/admin/admin-jobs-table.js" /* webpackChunkName: "component---src-pages-admin-admin-jobs-table-js" */),
  "component---src-pages-admin-admin-tags-js": () => import("./../../../src/pages/admin/admin-tags.js" /* webpackChunkName: "component---src-pages-admin-admin-tags-js" */),
  "component---src-pages-admin-blog-edit-[id]-js": () => import("./../../../src/pages/admin/blog-edit/[id].js" /* webpackChunkName: "component---src-pages-admin-blog-edit-[id]-js" */),
  "component---src-pages-admin-blog-upload-js": () => import("./../../../src/pages/admin/blog-upload.js" /* webpackChunkName: "component---src-pages-admin-blog-upload-js" */),
  "component---src-pages-admin-login-js": () => import("./../../../src/pages/admin-login.js" /* webpackChunkName: "component---src-pages-admin-login-js" */),
  "component---src-pages-admin-post-notfound-js": () => import("./../../../src/pages/admin/post-notfound.js" /* webpackChunkName: "component---src-pages-admin-post-notfound-js" */),
  "component---src-pages-admin-vacancy-edit-[id]-js": () => import("./../../../src/pages/admin/vacancy-edit/[id].js" /* webpackChunkName: "component---src-pages-admin-vacancy-edit-[id]-js" */),
  "component---src-pages-admin-vacancy-upload-js": () => import("./../../../src/pages/admin/vacancy-upload.js" /* webpackChunkName: "component---src-pages-admin-vacancy-upload-js" */),
  "component---src-pages-blogs-blod-search-js": () => import("./../../../src/pages/blogs/blod-search.js" /* webpackChunkName: "component---src-pages-blogs-blod-search-js" */),
  "component---src-pages-blogs-blog-notfound-js": () => import("./../../../src/pages/blogs/blog-notfound.js" /* webpackChunkName: "component---src-pages-blogs-blog-notfound-js" */),
  "component---src-pages-blogs-individual-blog-1-js": () => import("./../../../src/pages/blogs/individual-blog1.js" /* webpackChunkName: "component---src-pages-blogs-individual-blog-1-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-blogs-title-[id]-js": () => import("./../../../src/pages/blogs/title/[id].js" /* webpackChunkName: "component---src-pages-blogs-title-[id]-js" */),
  "component---src-pages-careers-bi-developer-js": () => import("./../../../src/pages/careers/BI-developer.js" /* webpackChunkName: "component---src-pages-careers-bi-developer-js" */),
  "component---src-pages-careers-data-engineer-js": () => import("./../../../src/pages/careers/data-engineer.js" /* webpackChunkName: "component---src-pages-careers-data-engineer-js" */),
  "component---src-pages-careers-designation-[id]-js": () => import("./../../../src/pages/careers/designation/[id].js" /* webpackChunkName: "component---src-pages-careers-designation-[id]-js" */),
  "component---src-pages-careers-devops-engineer-js": () => import("./../../../src/pages/careers/devops-engineer.js" /* webpackChunkName: "component---src-pages-careers-devops-engineer-js" */),
  "component---src-pages-careers-genetic-data-analyst-js": () => import("./../../../src/pages/careers/genetic-data-analyst.js" /* webpackChunkName: "component---src-pages-careers-genetic-data-analyst-js" */),
  "component---src-pages-careers-internship-js": () => import("./../../../src/pages/careers/internship.js" /* webpackChunkName: "component---src-pages-careers-internship-js" */),
  "component---src-pages-careers-java-engineer-js": () => import("./../../../src/pages/careers/java-engineer.js" /* webpackChunkName: "component---src-pages-careers-java-engineer-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-net-engineer-js": () => import("./../../../src/pages/careers/net-engineer.js" /* webpackChunkName: "component---src-pages-careers-net-engineer-js" */),
  "component---src-pages-careers-qa-automation-engineer-js": () => import("./../../../src/pages/careers/qa-automation-engineer.js" /* webpackChunkName: "component---src-pages-careers-qa-automation-engineer-js" */),
  "component---src-pages-careers-qa-engineer-js": () => import("./../../../src/pages/careers/qa-engineer.js" /* webpackChunkName: "component---src-pages-careers-qa-engineer-js" */),
  "component---src-pages-careers-react-engineer-js": () => import("./../../../src/pages/careers/react-engineer.js" /* webpackChunkName: "component---src-pages-careers-react-engineer-js" */),
  "component---src-pages-careers-senior-mob-app-dev-js": () => import("./../../../src/pages/careers/senior-mob-app-dev.js" /* webpackChunkName: "component---src-pages-careers-senior-mob-app-dev-js" */),
  "component---src-pages-careers-senior-mob-app-developer-js": () => import("./../../../src/pages/careers/senior-mob-app-developer.js" /* webpackChunkName: "component---src-pages-careers-senior-mob-app-developer-js" */),
  "component---src-pages-careers-software-developer-js": () => import("./../../../src/pages/careers/software-developer.js" /* webpackChunkName: "component---src-pages-careers-software-developer-js" */),
  "component---src-pages-careers-uiux-engineer-js": () => import("./../../../src/pages/careers/uiux-engineer.js" /* webpackChunkName: "component---src-pages-careers-uiux-engineer-js" */),
  "component---src-pages-careers-url-designation-js": () => import("./../../../src/pages/careers/[urlDesignation].js" /* webpackChunkName: "component---src-pages-careers-url-designation-js" */),
  "component---src-pages-careers-vp-operations-js": () => import("./../../../src/pages/careers/VpOperations.js" /* webpackChunkName: "component---src-pages-careers-vp-operations-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contract-rate-explorer-js": () => import("./../../../src/pages/contract-rate-explorer.js" /* webpackChunkName: "component---src-pages-contract-rate-explorer-js" */),
  "component---src-pages-culture-values-js": () => import("./../../../src/pages/culture-values.js" /* webpackChunkName: "component---src-pages-culture-values-js" */),
  "component---src-pages-custom-software-development-js": () => import("./../../../src/pages/custom-software-development.js" /* webpackChunkName: "component---src-pages-custom-software-development-js" */),
  "component---src-pages-data-handling-js": () => import("./../../../src/pages/data-handling.js" /* webpackChunkName: "component---src-pages-data-handling-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-devops-js": () => import("./../../../src/pages/devops.js" /* webpackChunkName: "component---src-pages-devops-js" */),
  "component---src-pages-diversity-inclusion-js": () => import("./../../../src/pages/diversity-inclusion.js" /* webpackChunkName: "component---src-pages-diversity-inclusion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-a-case-study-in-innovative-iot-solution-js": () => import("./../../../src/pages/insights/a-case-study-in-innovative-IOT-solution.js" /* webpackChunkName: "component---src-pages-insights-a-case-study-in-innovative-iot-solution-js" */),
  "component---src-pages-insights-a-smart-healthcare-platform-js": () => import("./../../../src/pages/insights/a-smart-healthcare-platform.js" /* webpackChunkName: "component---src-pages-insights-a-smart-healthcare-platform-js" */),
  "component---src-pages-insights-a-smart-wellness-digital-solution-js": () => import("./../../../src/pages/insights/a-smart-wellness-digital-solution.js" /* webpackChunkName: "component---src-pages-insights-a-smart-wellness-digital-solution-js" */),
  "component---src-pages-insights-boulder-administration-services-js": () => import("./../../../src/pages/insights/boulder-administration-services.js" /* webpackChunkName: "component---src-pages-insights-boulder-administration-services-js" */),
  "component---src-pages-insights-design-a-software-solution-to-assess-child-bullying-js": () => import("./../../../src/pages/insights/design-a-software-solution-to-assess-child-bullying.js" /* webpackChunkName: "component---src-pages-insights-design-a-software-solution-to-assess-child-bullying-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-iot-js": () => import("./../../../src/pages/iot.js" /* webpackChunkName: "component---src-pages-iot-js" */),
  "component---src-pages-job-apply-form-js": () => import("./../../../src/pages/job-apply-form.js" /* webpackChunkName: "component---src-pages-job-apply-form-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-mob-app-js": () => import("./../../../src/pages/mob-app.js" /* webpackChunkName: "component---src-pages-mob-app-js" */),
  "component---src-pages-news-2-js": () => import("./../../../src/pages/news2.js" /* webpackChunkName: "component---src-pages-news-2-js" */),
  "component---src-pages-news-3-js": () => import("./../../../src/pages/news3.js" /* webpackChunkName: "component---src-pages-news-3-js" */),
  "component---src-pages-news-4-js": () => import("./../../../src/pages/news4.js" /* webpackChunkName: "component---src-pages-news-4-js" */),
  "component---src-pages-news-5-js": () => import("./../../../src/pages/news5.js" /* webpackChunkName: "component---src-pages-news-5-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-list-js": () => import("./../../../src/pages/news-list.js" /* webpackChunkName: "component---src-pages-news-list-js" */),
  "component---src-pages-our-company-js": () => import("./../../../src/pages/our-company.js" /* webpackChunkName: "component---src-pages-our-company-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-provider-search-js": () => import("./../../../src/pages/provider-search.js" /* webpackChunkName: "component---src-pages-provider-search-js" */),
  "component---src-pages-ssodemo-js": () => import("./../../../src/pages/ssodemo.js" /* webpackChunkName: "component---src-pages-ssodemo-js" */),
  "component---src-pages-tara-js": () => import("./../../../src/pages/tara.js" /* webpackChunkName: "component---src-pages-tara-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-ui-ux-js": () => import("./../../../src/pages/ui-ux.js" /* webpackChunkName: "component---src-pages-ui-ux-js" */),
  "component---src-pages-video-build-query-js": () => import("./../../../src/pages/video/build-query.js" /* webpackChunkName: "component---src-pages-video-build-query-js" */),
  "component---src-pages-video-claims-summary-reports-js": () => import("./../../../src/pages/video/claims-summary-reports.js" /* webpackChunkName: "component---src-pages-video-claims-summary-reports-js" */),
  "component---src-pages-video-claims-upload-and-summary-js": () => import("./../../../src/pages/video/claims-upload-and-summary.js" /* webpackChunkName: "component---src-pages-video-claims-upload-and-summary-js" */),
  "component---src-pages-video-custom-reports-js": () => import("./../../../src/pages/video/custom-reports.js" /* webpackChunkName: "component---src-pages-video-custom-reports-js" */),
  "component---src-pages-video-data-aggregration-js": () => import("./../../../src/pages/video/data-aggregration.js" /* webpackChunkName: "component---src-pages-video-data-aggregration-js" */),
  "component---src-pages-video-mrf-data-overview-js": () => import("./../../../src/pages/video/mrf-data-overview.js" /* webpackChunkName: "component---src-pages-video-mrf-data-overview-js" */),
  "component---src-pages-video-mrf-quality-assurance-summary-js": () => import("./../../../src/pages/video/mrf-quality-assurance-summary.js" /* webpackChunkName: "component---src-pages-video-mrf-quality-assurance-summary-js" */),
  "component---src-pages-video-provider-group-use-case-js": () => import("./../../../src/pages/video/provider-group-use-case.js" /* webpackChunkName: "component---src-pages-video-provider-group-use-case-js" */),
  "component---src-pages-video-standard-reports-js": () => import("./../../../src/pages/video/standard-reports.js" /* webpackChunkName: "component---src-pages-video-standard-reports-js" */),
  "component---src-pages-web-app-js": () => import("./../../../src/pages/web-app.js" /* webpackChunkName: "component---src-pages-web-app-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-why-work-with-us-js": () => import("./../../../src/pages/why-work-with-us.js" /* webpackChunkName: "component---src-pages-why-work-with-us-js" */)
}

